import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const ShowMessageModal = ({ open, onClose, isSuccess, title, message }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "500px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          {isSuccess ? (
            <CheckCircleIcon sx={{ color: "green", fontSize: 40 }} />
          ) : (
            <ErrorIcon sx={{ color: "red", fontSize: 40 }} />
          )}
          <Typography variant="h6" sx={{ ml: 2 }}>
            {title}
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              padding: "8px 20px !important",
              background: "#629584",
              textTransform: "capitalize",
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShowMessageModal;
