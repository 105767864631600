import React from "react";
import { Box, Button, TextField, Typography, Modal } from "@mui/material";
import { useForm } from "react-hook-form";

const AddPlanModal = ({ open, onClose, onAddPlan }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  // Watch the price field to validate its value
  const price = watch("price");

  const onSubmit = (data) => {
    onAddPlan(data, reset); // Pass reset to onAddPlan
    reset();
    onClose(); // Close the modal after adding the plan
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" mb={2}>
          Add New Plan
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("name", { required: true })}
            error={Boolean(errors.name)}
            helperText={errors.name ? "Name is required" : ""}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("description", { required: true })}
            error={Boolean(errors.description)}
            helperText={errors.description ? "Description is required" : ""}
          />
          <TextField
            label="Price"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("price", {
              required: true,
              valueAsNumber: true,
              validate: {
                positive: (value) => value > 0 || "Price must be positive",
                max: (value) => value <= 10000 || "Price cannot exceed 10,000",
              },
            })}
            error={Boolean(errors.price)}
            helperText={errors.price ? errors.price.message : ""}
            // Change border color if price exceeds 10,000
            InputProps={{
              style: {
                borderColor: price > 10000 ? "red" : undefined,
                borderWidth: price > 10000 ? "2px" : undefined,
                boxShadow: price > 10000 ? "0 0 5px red" : undefined,
              },
            }}
          />
          <TextField
            label="Quantity"
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("quantity", { required: true, valueAsNumber: true })}
            error={Boolean(errors.quantity)}
            helperText={errors.quantity ? "Quantity is required" : ""}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              padding: "8px 20px !important",
              background: "#629584",
              textTransform: "capitalize",
            }}
          >
            Add New Plan
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddPlanModal;
