import React from "react";
import { Box, Button, TextField, Typography, Modal } from "@mui/material";
import { useForm } from "react-hook-form";

const AddCustomerModal = ({ open, onClose, onAddCustomer }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    onAddCustomer(data, reset); // Pass reset to onAddCustomer
    reset();
    onClose(); // Close the modal after adding the customer
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" mb={2}>
          Add New Customer
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("name", { required: true })}
            error={Boolean(errors.name)}
            helperText={errors.name ? "Name is required" : ""}
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("email", { required: true })}
            error={Boolean(errors.email)}
            helperText={errors.email ? "Email is required" : ""}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              padding: "8px 20px !important",
              background: "#629584",
              textTransform: "capitalize",
            }}
          >
            Add Customer
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddCustomerModal;
