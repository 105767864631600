import stripelogo from "./stripe-seeklogo.svg";
import "./App.css";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddPlanModal from "./AddPlanModal";
import Loader from "./Loader";
import AddCustomerModal from "./AddCustomerModal";
import ShowMessageModal from "./ShowMessageModal";

function App() {
  const [plans, setPlans] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false); // Loading state
  const [selectedPlan, setSelectedPlan] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);
  const [isAddingPlan, setIsAddingPlan] = useState(false);
  const [prices, setPrices] = useState([]);
  const [selectedChips, setSelectedChips] = useState([
    {
      id: 1,
      label: "Canadian pre-authorized debt",
      value: "acss_debit",
      isActive: true,
    },
    { id: 2, label: "Card", value: "card", isActive: true },
    { id: 3, label: "Link", value: "link", isActive: true },
  ]);

  const handleDeleteChip = (id) => {
    setSelectedChips((prevChips) =>
      prevChips.map((chip) =>
        chip.id === id ? { ...chip, isActive: false } : chip
      )
    );
  };

  const [error, setError] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    getCustomer(data.email);
  };

  const handleChange = (event) => {
    const { value } = event.target;

    const selectedPlan = JSON.parse(value);
    setSelectedPlan(selectedPlan);
    setError(false);
  };
  // Fetch plans function remains unchanged
  // const fetchPlans = async () => {
  //   const config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: process.env.REACT_APP_API_URL + "/api/getallplans",
  //   };

  //   try {
  //     const response = await axios.request(config);
  //     const { success, products } = response.data;
  //     if (success) {
  //       setPlans(products || []);
  //       if (products && products.length > 0) {
  //         // If you want to select the first plan when fetching initially
  //         setSelectedPlan({
  //           id: products[0].id,
  //           price: products[0].default_price,
  //         });
  //       }
  //       setLoading(false);
  //     } else {
  //       console.error("Response error msg", response.data.message);
  //     }
  //   } catch (err) {
  //     console.error("Error", err.message);
  //     setLoading(false);
  //   }
  // };

  const getCustomer = async (email) => {
    if (email !== "") {
      setLoading(true); // Set loading to true when starting the fetch
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/api/getallcustomers?email=${email}`,
      };

      try {
        const response = await axios.request(config);
        const { success, message, customers } = response.data;
        if (success) {
          setCustomers(customers[0]); // Update state with response data
          setLoading(false); // Stop loading
        } else {
          console.error("Response error message", message);
          setLoading(false);
        }
      } catch (err) {
        console.error("Error", err.message);
        setLoading(false);
      }
    }
  };

  const Checkout = async () => {
    if (selectedPlan?.price && customers?.id) {
      setLoader(true); // Show loading indicator while processing
      setError(false);

      const paymentMethodTypes = selectedChips
        .filter((chip) => chip.isActive)
        .map((chip) => chip.value);

      const pricesArray = prices.map((price) => ({
        priceId: price.priceId,
        productId: price.productId,
        quantity: price.quantity,
      }));

      const data = JSON.stringify({
        customerId: customers.id,
        payment_method_types: paymentMethodTypes,
        quantity: pricesArray.length || 1,
        prices: pricesArray,
      });

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/createcheckout`,
        headers: { "Content-Type": "application/json" },
        data,
      };

      try {
        const response = await axios.request(config);
        const { success, message } = response.data;

        if (success) {
          setModalTitle("Success");
          setModalMessage("Invoice sent successfully!");
          setIsSuccess(true);
        } else {
          setModalTitle("Error");
          setModalMessage(`Failed to send invoice: ${message}`);
          setIsSuccess(false);
        }
        setOpenModal(true); // Open modal to display result
      } catch (error) {
        console.error("Error", error.message);
        setModalTitle("Error");
        setModalMessage("An error occurred while processing your request.");
        setIsSuccess(false);
        setOpenModal(true);
      } finally {
        setLoader(false); // Hide loading indicator
        resetAll();
      }
    } else {
      setModalTitle("Error");
      setModalMessage("Please select a plan and a customer.");
      setIsSuccess(false);
      setOpenModal(true);
    }
  };

  const handleAddNewPlan = async (planData) => {
    setLoader(true); // Show loading indicator while processing

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/addplan`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(planData),
    };

    try {
      const response = await axios.request(config);
      const { success, message, product } = response.data;

      if (success) {
        setModalTitle("Success");
        setModalMessage("Plan added successfully!");
        setIsSuccess(true);

        setPrices((prevPrices) => [
          ...prevPrices,
          {
            productId: product.id,
            priceId: product.default_price,
            quantity: planData.quantity,
            name: planData.name,
            price: planData.price,
          },
        ]);

        setSelectedPlan({
          id: product.id,
          price: product.default_price,
        });
      } else {
        setModalTitle("Error");
        setModalMessage(`Failed to add plan: ${message}`);
        setIsSuccess(false);
      }
      setOpenModal(true); // Show result modal
    } catch (error) {
      console.error("Error adding plan:", error);
      setModalTitle("Error");
      setModalMessage("An error occurred while adding the plan.");
      setIsSuccess(false);
      setOpenModal(true);
    } finally {
      setLoader(false); // Hide loading indicator
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddCustomer = async (customerData) => {
    setLoader(true); // Show loading indicator while processing

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/addcustomer`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(customerData),
    };

    try {
      const response = await axios.request(config);
      const { success, message } = response.data;

      if (success) {
        setModalTitle("Success");
        setModalMessage("Customer added successfully!");
        setIsSuccess(true);
        setValue("email", customerData.email); // Set the email in the form

        await getCustomer(customerData.email); // Fetch customer data after adding
      } else {
        setModalTitle("Error");
        setModalMessage(`Failed to add customer: ${message}`);
        setIsSuccess(false);
      }
      setOpenModal(true); // Show result modal
    } catch (error) {
      console.error("Error adding customer:", error);
      setModalTitle("Error");
      setModalMessage("An error occurred while adding the customer.");
      setIsSuccess(false);
      setOpenModal(true);
    } finally {
      setLoader(false); // Hide loading indicator
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // resetAll(); // Call reset function when closing the modal
  };

  const resetAll = () => {
    // Resetting all necessary states
    setPrices([]); // Reset prices array
    setSelectedPlan(null); // Reset selected plan
    setCustomers({}); // Reset customer data
    // setSelectedChips([]);
    reset();
    setError(false); // Reset any error flags
    // Reset any other states related to forms if necessary
  };

  return (
    <>
      <div className="App">
        <ToastContainer />
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            paddingX: 6,
            paddingY: 2,
            backgroundColor: "#E2F1E7",
            height: "80px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img src={stripelogo} className="App-logo" alt="logo" />
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingX: 6,
            paddingY: 6,
            background: "white",
          }}
        >
          {loader ? ( // Conditional rendering based on loading state
            <Loader />
          ) : (
            <Box
              sx={{
                border: "1px solid #00000080",
                borderRadius: "10px",
                minHeight: "500px",
                padding: 2,
              }}
            >
              <Typography
                variant="h3"
                fontFamily={"Mukta"}
                fontWeight={600}
                textAlign={"center"}
                color={"#629584"}
              >
                Send Invoice
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 3,
                  gap: 1,
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="p"
                  fontSize={"18px"}
                  fontWeight={500}
                  fontFamily={"Mukta"}
                  color={"#52525B"}
                >
                  Select Your Plan
                </Typography>
                {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="select"
                sx={{ width: "400px" }}
                value={JSON.stringify(selectedPlan)} // Use JSON.stringify to match the value
                label="Plan"
                onChange={handleChange}
              >
                {plans?.data?.map((plan, index) => (
                  <MenuItem
                    key={index}
                    value={JSON.stringify({
                      id: plan.id,
                      price: plan.default_price,
                    })}
                  >
                    {plan.name}
                  </MenuItem>
                ))}
              </Select> */}

                {/* Add Plan Button */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setModalOpen(true)} // Open modal on button click
                  sx={{
                    padding: "8px 20px !important",
                    background: "#629584",
                    textTransform: "capitalize",
                  }}
                >
                  Add New Plan
                </Button>
                {/* List of Added Plans */}
                <List sx={{ width: "25%" }}>
                  {prices.map((price, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between", // Space between elements
                          padding: "8px 0", // Add some vertical padding if needed
                        }}
                      >
                        <Typography variant="body1">
                          {price.name} x {price.quantity}
                        </Typography>
                        <Typography variant="body1">${price.price}</Typography>
                      </ListItem>
                      {index < prices.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 3,
                  gap: 1,
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="p"
                  fontSize={"18px"}
                  fontWeight={500}
                  fontFamily={"Mukta"}
                  color={"#52525B"}
                >
                  Search Customer by email
                </Typography>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ width: "100%" }}
                >
                  <Box display={"flex"} gap={2}>
                    <div>
                      <TextField
                        placeholder={"Enter Customer Email"}
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        sx={{ width: "400px" }}
                        className="input"
                        error={!!errors.email} // Show error if email is invalid
                        helperText={errors.email ? errors.email.message : ""} // Display error message
                      />
                    </div>
                    <Button
                      type="submit" // Change button type to submit
                      variant="contained"
                      sx={{
                        padding: "8px 20px !important",
                        background: "#629584",
                        textTransform: "capitalize",
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </form>
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => setOpen(true)}
                    sx={{
                      padding: "8px 20px !important",
                      background: "#629584",
                      textTransform: "capitalize",
                    }}
                  >
                    Add Customer
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: 3,
                  padding: 4,
                  borderRadius: "12px",
                  backgroundColor: "#62958440",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {customers?.email ? (
                  <>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography
                            variant="p"
                            fontSize={"20px"}
                            fontWeight={600}
                            fontFamily={"Mukta"}
                            color={"#52525B"}
                            textAlign={"left"}
                          >
                            Customer Name
                          </Typography>
                          <Typography
                            variant="p"
                            fontSize={"18px"}
                            fontWeight={400}
                            fontFamily={"Mukta"}
                            color={"#52525B"}
                            textAlign={"left"}
                          >
                            {customers.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 2,
                          }}
                        >
                          <Typography
                            variant="p"
                            fontSize={"20px"}
                            fontWeight={600}
                            fontFamily={"Mukta"}
                            color={"#52525B"}
                            textAlign={"left"}
                          >
                            Email
                          </Typography>

                          <Typography
                            variant="p"
                            fontSize={"18px"}
                            fontWeight={400}
                            fontFamily={"Mukta"}
                            color={"#52525B"}
                            textAlign={"left"}
                          >
                            {customers.email}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {selectedChips.map(
                          (chip) =>
                            chip.isActive && (
                              <Chip
                                key={chip.id}
                                label={chip.label}
                                onDelete={() => handleDeleteChip(chip.id)}
                                color="primary"
                                sx={{
                                  background: "#629584",
                                  margin: "0 5px",
                                  alignSelf: "flex-end",
                                }}
                              />
                            )
                        )}
                        <Button
                          variant="contained"
                          sx={{
                            padding: "8px 20px !important",
                            background: "#629584",
                            alignSelf: "flex-end",
                            textTransform: "capitalize",
                            marginLeft: "40px",
                          }}
                          onClick={() => Checkout()}
                        >
                          Invoice
                        </Button>
                      </Box>
                    </Box>
                    {error && (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Typography
                          variant="p"
                          fontSize={"16px"}
                          fontWeight={400}
                          fontFamily={"Mukta"}
                          color={"#f00"}
                        >
                          Please select a Plan
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box width={"100%"}>
                    <Typography
                      variant="p"
                      fontSize={"20px"}
                      fontWeight={600}
                      fontFamily={"Mukta"}
                      color={"#52525B"}
                      textAlign={"center"}
                    >
                      No Searched Customer
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {/* AddPlanModal */}
        <AddPlanModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onAddPlan={handleAddNewPlan} // Pass the handler to add a new plan
        />
        {/* Render the AddCustomerModal */}
        <AddCustomerModal
          open={open}
          onClose={handleClose}
          onAddCustomer={handleAddCustomer}
        />

        {/* Show Message Modal */}
        <ShowMessageModal
          open={openModal}
          onClose={handleCloseModal}
          title={modalTitle}
          message={modalMessage}
          isSuccess={isSuccess}
        />
      </div>
    </>
  );
}

export default App;
